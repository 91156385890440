import {
  type RouteDefinition,
  type RouteSectionProps,
  createAsync,
  action,
} from "@solidjs/router";
import { getArticleCache } from "~/services";
import * as articleService from "~/services/articles";
import dompurify from "dompurify";
import ArticleForm from "~/components/ArticleForm";
import SuspenseWrap from "~/components/SuspenseWrap";

export const route = {
  load({ params }) {
    getArticleCache(params.id);
  },
} satisfies RouteDefinition;

const toSubmit = action(async (formData: FormData) => {
  const id = formData.get("id")?.toString() || "";
  const title = formData.get("title")?.toString() || "";
  const _content = formData.get("content")?.toString() || "";
  const content = dompurify.sanitize(_content);
  await articleService.update(id, { title, content });
  // return redirect(`/dash/articles/${id}`);
}, "dash/articles/[id]#FormSubmit");

export default function Submit(props: RouteSectionProps) {
  const id = () => props.params.id;
  const data = createAsync(() => getArticleCache(id()), {
    deferStream: true,
  });

  return (
    <SuspenseWrap>
      <ArticleForm action={toSubmit} data={data} />
    </SuspenseWrap>
  );
}
