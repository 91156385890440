import {
  A,
  cache,
  createAsync,
  type RouteDefinition,
  type RouteSectionProps,
} from "@solidjs/router";
import { For } from "solid-js";
import { Title } from "@solidjs/meta";
import { knowsIndex } from "~/utils";
import { useAppState } from "~/AppContext";
import SuspenseWrap from "~/components/SuspenseWrap";

const getKnows = cache(async (q: string, page: number) => {
  return knowsIndex.search(q, { page });
}, "search");

export const route = {
  load({ location }) {
    getKnows(location.query.q, +location.query.page || 1);
  },
} satisfies RouteDefinition;

export default function Home(props: RouteSectionProps) {
  const { t } = useAppState();
  const query = () => props.location.query.q;
  const page = () => +props.location.query.page || 1;
  const data = createAsync(() => getKnows(query(), page()));
  return (
    <SuspenseWrap class="max-w-screen-xl mx-auto p-4 w-full flex flex-col divide-y">
      <Title>{t("app.title")}</Title>
      <For each={data()?.hits || []}>
        {(know, i) => (
          <A class="py-4 prose max-w-none" href={`/n/${know.id}`}>
            <h2>{know.essence}</h2>
            <p>{know.body}</p>
          </A>
        )}
      </For>
    </SuspenseWrap>
  );
}
