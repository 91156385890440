import { For, Show } from "solid-js";
import {
  createAsync,
  useSubmission,
  cache,
  type RouteDefinition,
  type RouteSectionProps,
} from "@solidjs/router";
import ImplyHead from "~/components/ImplyHead";
import SuspenseWrap from "~/components/SuspenseWrap";
import { Spinner } from "~/components/icons";
import { useAppState } from "~/AppContext";
import { createReviewAct, queryImplyFullCache } from "~/services";
import * as repo from "~/repos/review";

const limit = 20;
const getReviews = cache(async (implyId: string, page: number) => {
  "use server";
  return await repo.query({ implyId }, { page, size: limit });
}, "i/[id]#reviews");

export const route = {
  load({ params, location }) {
    queryImplyFullCache(params.id);
    getReviews(params.id, +location.query.page || 1);
  },
} satisfies RouteDefinition;

export default function ImplyDetails(props: RouteSectionProps) {
  const { t } = useAppState();
  const id = () => props.params.id;
  // const query = () => props.location.query.q;
  const page = () => +props.location.query.page || 1;
  const imply = createAsync(() => queryImplyFullCache(id()), {
    deferStream: true,
  });

  const reviewsQuery = createAsync(() => getReviews(id(), page()), {
    deferStream: true,
  });

  const state = useSubmission(createReviewAct);
  let inputRef!: HTMLTextAreaElement;

  return (
    <SuspenseWrap class="max-w-screen-xl mx-auto p-4 w-full">
      <Show
        when={imply()}
        fallback={
          <div>
            <Spinner />
          </div>
        }
      >
        <div class="">
          <ImplyHead imply={imply()!} linkable={true} />
        </div>
        <hr class="my-4" />
        <div
          class="prose prose-lg dark:prose-invert mx-auto"
          innerHTML={imply()?.inference}
        />
        <form
          action={createReviewAct}
          method="post"
          class="max-w-prose prose-lg mx-auto mt-4"
          onSubmit={(e) => {
            if (!inputRef.value.trim()) e.preventDefault();
            setTimeout(() => (inputRef.value = ""));
          }}
        >
          <input name="implyId" value={id()} type="hidden" />
          <textarea
            class=" border-2 w-full dark:bg-gray-900 bg-white"
            name="content"
            ref={inputRef}
          />
          <button
            type="submit"
            disabled={state.pending}
            class="border-2 px-6 py-1"
          >
            {t("comment")}
          </button>
        </form>
        <For each={reviewsQuery()?.hits}>
          {(review) => (
            <div class="max-w-prose prose-lg mx-auto flex md:flex-row my-4">
              <div class="grow prose dark:prose-invert pt-2 border-t-1 border-t">
                <h5>{review.content}</h5>
              </div>
              <Show when={review?.author?.avatar}>
                <img class="h-6 m-2" src={review?.author?.avatar!} />
              </Show>
            </div>
          )}
        </For>
      </Show>
    </SuspenseWrap>
  );
}
