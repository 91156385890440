import {
  type RouteSectionProps,
  type RouteDefinition,
  createAsync,
} from "@solidjs/router";
import Nav from "~/components/BlogNav";
import Footer from "~/components/Footer";
import { getUserCache } from "~/services";

export const route = {
  load: () => getUserCache(),
} satisfies RouteDefinition;

export default function Dash(props: RouteSectionProps) {
  const currentUser = createAsync(() => getUserCache(), { deferStream: true });
  return (
    <div>
      <Nav name={props.params.name} currentUser={currentUser} />
      {props.children}
      <Footer />
    </div>
  );
}
