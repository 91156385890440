import {
  A,
  createAsync,
  useSubmission,
  type RouteDefinition,
} from "@solidjs/router";
import { Blog, Mailbox } from "~/components/icons";
import { getUserCache } from "~/services";
import { useAppState } from "~/AppContext";
import { logoutAct } from "~/services";

export const route = {
  load: () => getUserCache(),
} satisfies RouteDefinition;

export default function () {
  const { t } = useAppState();
  const current = createAsync(() => getUserCache(), { deferStream: true });

  const loggingOut = useSubmission(logoutAct);
  return (
    <section class="min-h-96 mt-4">
      <table class="table-auto border-separate border-spacing-4">
        <thead>
          <tr>
            <th>{t("displayName")}</th>
            <th class="text-left">{current()?.displayName}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <Mailbox class="h-6 w-6" />
            </td>
            <td>{current()?.name}</td>
          </tr>
          <tr>
            <td>
              <Blog class="h-6 w-6" />
            </td>
            <td>
              <A href={`/blog/${current()?.name}`}>{t("dash.my_blog")}</A>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="flex justify-end text-right p-4">
        <form action={logoutAct} method="post">
          <button
            name="logout"
            type="submit"
            disabled={loggingOut.pending}
            class="block text-left border-2 bg-gray-100 dark:bg-gray-800 md:bg-inherit cursor-pointer rounded hover:outline hover:outline-2 px-4 py-2"
          >
            {t("signout")}
          </button>
        </form>
      </div>
    </section>
  );
}
