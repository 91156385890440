import {
  type RouteDefinition,
  type RouteSectionProps,
  createAsync,
  action,
  redirect,
} from "@solidjs/router";
import { getArticleCache } from "~/services";
import * as articleService from "~/services/articles";
import { useAppState } from "~/AppContext";
import { JSX, Show } from "solid-js";
import { Spinner } from "~/components/icons";
import SuspenseWrap from "~/components/SuspenseWrap";

export const route = {
  load: ({ params }) => getArticleCache(params.id),
} satisfies RouteDefinition;

const toSubmit = action(async (formData: FormData) => {
  const id = formData.get("id")?.toString() || "";
  const published = formData.get("published")?.toString();
  const abstract = formData.get("abstract")?.toString() || "";
  await articleService.update(id, {
    abstract,
    publishedAt: Boolean(published) ? new Date() : null,
  });
  throw redirect(`/dash/articles/${id}`);
}, "dash/articles/[id]/setting#Submit");

const preventEnter: JSX.EventHandler<HTMLTextAreaElement, KeyboardEvent> = (
  e
) => {
  if (e.key === "Enter") e.preventDefault();
};

export default function Submit(props: RouteSectionProps) {
  const id = () => props.params.id;
  const data = createAsync(() => getArticleCache(id()), {
    deferStream: true,
  });

  const { t } = useAppState();
  return (
    <SuspenseWrap class="mt-8">
      <Show
        when={data()}
        fallback={
          <div>
            <Spinner />
          </div>
        }
      >
        <form action={toSubmit} method="post" class="flex flex-col gap-4 ">
          <input name="id" value={data()!.id} hidden />
          <label class="px-4 inline-flex items-center cursor-pointer">
            <span class="me-4 text-lg font-medium text-gray-900 dark:text-gray-300">
              {t("dash.publish")}
            </span>
            <input
              type="checkbox"
              checked={data()!.publishedAt ? true : false}
              name="published"
              class="sr-only peer"
            />
            <div class="relative w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
          </label>
          <label for="abstract" class="px-4 text-lg">
            {t("abstract")}
          </label>
          <div class="prose prose-2xl mb-8">
            <textarea
              name="abstract"
              // @ts-ignore
              prop:value={data()!.abstract} // solidjs/solid-start/discussions/858
              maxLength={120}
              rows={6}
              placeholder={t("abstract")}
              class="px-4 text-gray-600 w-full border-0 focus:ring-0"
              onKeyDown={preventEnter}
            />
          </div>
          <div>
            <button
              class="mx-4 items-center px-8 py-2 font-medium text-center rounded-sm outline outline-1 hover:dark:bg-gray-800 hover:bg-gray-100"
              type="submit"
            >
              {t("save")}
            </button>
          </div>
        </form>
      </Show>
    </SuspenseWrap>
  );
}
