import { For, Show } from "solid-js";
import {
  A,
  cache,
  useSearchParams,
  type RouteDefinition,
  type RouteSectionProps,
  createAsync,
} from "@solidjs/router";
import { useAppState } from "~/AppContext";
import Spinner from "~/components/Spinner";
import { Pen } from "~/components/icons";
import { Pagination } from "~/components/Pagination";
import SuspenseWrap from "~/components/SuspenseWrap";
import * as repo from "~/repos/review";
import { currentUserIdCache } from "~/services";

const limit = 20;

const getReviews = cache(async (q: string, page: number) => {
  "use server";
  const userId = await currentUserIdCache();
  if (!userId) throw new Error("not authored");
  return await repo.query({ authorId: userId }, { page, size: limit });
}, "dash/reviews");

export const route = {
  load({ location }) {
    getReviews(location.query.q || "", +location.query.page || 1);
  },
} satisfies RouteDefinition;

export default function DashReviews(props: RouteSectionProps) {
  const { t } = useAppState();
  const query = () => props.location.query.q;
  const page = () => +props.location.query.page || 1;
  const data = createAsync(() => getReviews(query(), page()), {
    deferStream: true,
  });

  const [, setSearchParams] = useSearchParams();

  return (
    <SuspenseWrap class="p-4">
      <Show
        when={data()}
        fallback={
          <div class="text-center my-12">
            <Spinner class="inline-block" />
          </div>
        }
      >
        <div class="divide-y divide-dotted divide-gray-800 dark:divide-gray-900 mb-4 ">
          <For each={data()!.hits}>
            {(review) => (
              <div class="flex flex-row">
                <A
                  class="grow block  max-w-prose"
                  href={`/i/${review.implyId}`}
                >
                  <div>
                    <p class="px-2">{review.content}</p>
                  </div>
                </A>
                <div class="flex flex-col">
                  <A href={`../edit-review/${review.id}`}>
                    <Pen />
                  </A>
                </div>
              </div>
            )}
          </For>
        </div>
        <Pagination
          totalPage={() => data()!.totalPages}
          page={() => data()!.page}
          goPrev={() => {
            setSearchParams({ page: data()!.page - 1 });
          }}
          goNext={() => {
            setSearchParams({ page: data()!.page + 1 });
          }}
        />
      </Show>
    </SuspenseWrap>
  );
}
