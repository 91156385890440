import {
  type RouteDefinition,
  type RouteSectionProps,
  createAsync,
  action,
  redirect,
} from "@solidjs/router";
import { createId } from "@paralleldrive/cuid2";
import ImplyForm from "~/components/ImplyForm";
import SuspenseWrap from "~/components/SuspenseWrap";
import { queryKnowImpliesCache } from "~/services";
import { create as createImplyAct } from "~/services/implies";

export const route = {
  load({ params }) {
    queryKnowImpliesCache(params.id);
  },
} satisfies RouteDefinition;

const toSubmit = action(async (formData: FormData) => {
  const knowId = formData.get("knowId")?.toString();
  if (!knowId) throw "knowId is undefined";

  const negative = formData.get("negative")?.toString() === "on";
  const propositionIds = formData.getAll("propositionIds[]") as string[];
  const id = createId();
  const val = { id, propositionIds, negative, inference: "" };
  await createImplyAct(knowId, val);
  throw redirect(`/i/${id}/edit`);
}, "n/:id/formula");

export default function KnowFormula(props: RouteSectionProps) {
  const id = () => props.params.id;
  const data = createAsync(() => queryKnowImpliesCache(id()), {
    deferStream: true,
  });

  return (
    <SuspenseWrap class="max-w-screen-xl mx-auto p-4 w-full">
      <ImplyForm
        data={() =>
          data() && {
            knowId: id(),
            know: data()!,
            propositions: [],
          }
        }
        action={toSubmit}
      />
    </SuspenseWrap>
  );
}
