import {
  type RouteDefinition,
  type RouteSectionProps,
  createAsync,
} from "@solidjs/router";
import { Show } from "solid-js";
import { Title } from "@solidjs/meta";
import { useAppState } from "~/AppContext";
import { getArticleCache } from "~/services";

export const route = {
  load({ params }) {
    getArticleCache(params.aid);
  },
} satisfies RouteDefinition;

export default function Know(props: RouteSectionProps) {
  const { t } = useAppState();
  const id = () => props.params.aid;
  const data = createAsync(() => getArticleCache(id()), {
    deferStream: true,
  });

  return (
    <div class="max-w-screen-xl mx-auto px-4">
      <Show when={data()}>
        <Title>{data()!.title}</Title>
        <article class="prose prose-lg prose dark:prose-invert mx-auto">
          <h1 class="font-extrabold text-4xl">{data()!.title}</h1>
          <hr class="my-4" />
          {/* <SolidMarkdown children={data()!.content} /> */}
          <div innerHTML={data()!.content} />
        </article>
      </Show>
    </div>
  );
}
