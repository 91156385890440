import {
  type RouteDefinition,
  type RouteSectionProps,
  A,
  createAsync,
  cache,
} from "@solidjs/router";
import { Title } from "@solidjs/meta";
import { For, Show } from "solid-js";
import Spinner from "~/components/Spinner";
import SuspenseWrap from "~/components/SuspenseWrap";
import { useAppState } from "~/AppContext";
import { queryKnowImpliesCache } from "~/services";
import { impliesIndex } from "~/utils";

const limit = 20;
const getImplies = cache(async (knowId: string, page: number, q?: string) => {
  return impliesIndex.search(q, {
    sort: ["createdAt:desc"],
    filter: `knowId = "${knowId}"`,
    hitsPerPage: limit,
    page,
  });
}, "n/[id]#implies");

export const route = {
  load({ params, location }) {
    queryKnowImpliesCache(params.id);
    getImplies(params.id, +location.query.page || 1, location.query.q);
  },
} satisfies RouteDefinition;

export default function KnowIndex(props: RouteSectionProps) {
  const { t } = useAppState();
  const id = () => props.params.id;
  const query = () => props.location.query.q;
  const page = () => +props.location.query.page || 1;
  const know = createAsync(() => queryKnowImpliesCache(id()), {
    deferStream: true,
  });
  const data = createAsync(() => getImplies(id(), page(), query()), {
    deferStream: true,
  });

  return (
    <SuspenseWrap class="max-w-screen-xl mx-auto px-4 w-full">
      <Show
        when={know()}
        fallback={
          <div>
            <Spinner />
          </div>
        }
      >
        <Title>{know()?.essence}</Title>
        <div class="flex flex-row">
          <div class="dark:prose-invert prose grow">
            <h3>
              <span class="dark:text-gray-500 text-gray-400 mr-2">
                {t("proposition")}:
              </span>
              {know()?.essence}
            </h3>

            <h5>{know()?.body}</h5>
          </div>
          <div>
            <A class="mx-1 p-1 border block" href={`/n/${id()}/formula`}>
              {t("add_proof")}
            </A>
          </div>
        </div>
        <hr class="mt-4" />
      </Show>
      <Show when={Array.isArray(data()?.hits)}>
        <For each={data()?.hits}>
          {(imply) => (
            <div class="flex flex-row mt-4">
              <div class="grow dark:prose-invert prose dark:bg-gray-800 bg-gray-100">
                <ul class="not-prose dark:bg-gray-700 bg-gray-200 list-disc marker:text-green-500">
                  <Show when={imply.propositions}>
                    <For each={imply.propositions}>
                      {(propos) => (
                        <li>
                          <div>{propos.essence}</div>
                        </li>
                      )}
                    </For>
                    {(!imply.propositions ||
                      imply.propositions.length === 0) && (
                      <h4 class="h-8 dark:text-gray-500 text-gray-400">
                        {t("priori_proposition")}
                      </h4>
                    )}
                  </Show>
                </ul>
                <article class="px-2">
                  <div innerHTML={imply.inference} />
                </article>
              </div>
              <div>
                <A class="mx-1 p-1 border block" href={`/i/${imply.id}`}>
                  {t("show_more")}
                </A>
              </div>
            </div>
          )}
        </For>
      </Show>
    </SuspenseWrap>
  );
}
