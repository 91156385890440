// @refresh reload
import { Router } from "@solidjs/router";
import { FileRoutes } from "@solidjs/start/router";
import { Suspense, ErrorBoundary } from "solid-js";
import "./app.css";
import { MetaProvider } from "@solidjs/meta";
import { HttpStatusCode } from "@solidjs/start";
import * as Sentry from "@sentry/browser";
import { AppContextProvider } from "~/AppContext";
import { Spinner } from "~/components/icons";

export default function App() {
  return (
    <Router
      root={(props) => (
        <ErrorBoundary
          fallback={(error) => {
            Sentry.captureException(error);
            return <HttpStatusCode code={404} />;
          }}
        >
          <AppContextProvider>
            {/* MetaProvider must be not wrapped in suspense */}
            <MetaProvider>
              <Suspense
                fallback={
                  <div class="text-center my-16">
                    <Spinner size={10} class="inline-block" />
                  </div>
                }
              >
                {props.children}
              </Suspense>
            </MetaProvider>
          </AppContextProvider>
        </ErrorBoundary>
      )}
    >
      <FileRoutes />
    </Router>
  );
}
