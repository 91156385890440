import { For, Show } from "solid-js";
import {
  A,
  cache,
  useSearchParams,
  type RouteDefinition,
  type RouteSectionProps,
  createAsync,
} from "@solidjs/router";
import { Title } from "@solidjs/meta";
import { useAppState } from "~/AppContext";
import Spinner from "~/components/Spinner";
import { Pagination } from "~/components/Pagination";
import { fetchUserCache } from "~/services";
import * as repo from "~/repos/article";

const limit = 20;
const queryBlog = cache(async (q: string, page: number, name: string) => {
  "use server";
  const user = await fetchUserCache(name);
  if (!user) throw new Error("Blog not found");
  return await repo.query(
    { query: q, authorId: user.id, published: true },
    { size: limit, page }
  );
}, "blog");

export const route = {
  load({ location, params }) {
    queryBlog(location.query.q, +location.query.page || 1, params.name);
  },
} satisfies RouteDefinition;

export default function DashArticles(props: RouteSectionProps) {
  const { t } = useAppState();
  const query = () => props.location.query.q;
  const page = () => +props.location.query.page || 1;
  const name = () => props.params.name;
  const data = createAsync(() => queryBlog(query(), page(), name()), {
    deferStream: true,
  });

  const [, setSearchParams] = useSearchParams();

  return (
    <div class="max-w-screen-xl mx-auto p-4 w-full">
      <Title>{name()}</Title>
      <Show
        when={data()}
        fallback={
          <div class="text-center my-12">
            <Spinner class="inline-block" />
          </div>
        }
      >
        <div class="mb-4">
          <For each={data()!.hits}>
            {(article) => (
              <A
                class="grow pt-1 pb-2 block prose dark:prose-invert max-w-none
                last:border-none border-b border-gray-200 dark:border-gray-700"
                href={`a/${article.id}`}
              >
                <h1>{article.title}</h1>
                <p>{article.abstract}</p>
              </A>
            )}
          </For>
        </div>
        <Pagination
          totalPage={() => data()!.totalPages}
          page={() => data()!.page}
          goPrev={() => {
            setSearchParams({ page: data()!.page - 1 });
          }}
          goNext={() => {
            setSearchParams({ page: data()!.page + 1 });
          }}
        />
      </Show>
    </div>
  );
}
