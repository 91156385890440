import {
  type RouteSectionProps,
  type RouteDefinition,
  createAsync,
} from "@solidjs/router";
import Nav from "~/components/Nav";
import { getUserCache } from "~/services";
import { Title } from "@solidjs/meta";
import { useAppState } from "~/AppContext";
import Footer from "~/components/Footer";

export const route = {
  load: () => getUserCache(),
} satisfies RouteDefinition;

export default function Dash(props: RouteSectionProps) {
  // deferStream: false cause page content gone on mobile devices
  const currentUser = createAsync(() => getUserCache(), { deferStream: true });
  const { t } = useAppState();
  return (
    <div class="sm:max-md:h-full flex flex-col items-stretch box-border">
      <Title>{t("app.title")}</Title>
      <Nav currentUser={currentUser} />
      {props.children}
      <Footer />
    </div>
  );
}
