import { createEffect, createSignal, For, JSX, Show } from "solid-js";
import {
  type RouteDefinition,
  type RouteSectionProps,
  cache,
  createAsync,
  useSubmission,
  action,
  redirect,
} from "@solidjs/router";
import { reject, any, propEq, eqProps } from "ramda";
import { queryImplyFullCache } from "~/services";
import { update as UpdateImplyAct } from "~/services/implies";
import { useAppState } from "~/AppContext";
import { knowsIndex } from "~/utils";
import { PlusCircle } from "~/components/icons";

export const route = {
  load({ params }) {
    queryImplyFullCache(params.id);
  },
} satisfies RouteDefinition;

interface Propost {
  id: string;
  essence: string;
  body: string;
}

const qeuryKnows = cache(async (q) => {
  if (q) {
    const res = await knowsIndex?.search(q);
    return res;
  }
  return undefined;
}, "i/edit#SearchKnows");

const toSubmit = action(async (formData: FormData) => {
  const id = formData.get("id")?.toString() || "";
  const negative = formData.get("negative")?.toString() === "on";
  const published = formData.get("published")?.toString();
  const propositionIds = formData.getAll("propositionIds[]") as string[];
  const val = {
    propositionIds,
    negative,
    publishedAt: published ? new Date() : null,
  };
  await UpdateImplyAct(id, val);
  throw redirect(`/dash/implies`);
}, "i/editProps#form");

export default function KnowFormula(props: RouteSectionProps) {
  let modalRef!: HTMLDivElement;
  const { t } = useAppState();
  const id = () => props.params.id;
  const state = useSubmission(toSubmit);
  const [query, setQuery] = createSignal("");

  const data = createAsync(() => queryImplyFullCache(id()), {
    deferStream: true,
  });
  const [propos, setPropost] = createSignal<Propost[]>(
    data()?.propositions || []
  );

  const addPropsId = (know: Propost) => {
    const found = any(eqProps("id", know), propos());
    if (!found) {
      setPropost((p) => [...p, know]);
      modalRef.style.display = "none";
    }
  };

  const delPropsId = (id: string) => {
    const _ = propEq<string, "id">(id, "id");
    return setPropost((p) => reject<Propost>(_)(p));
  };

  const searchData = createAsync(() => qeuryKnows(query()), {
    deferStream: true,
  });

  const searchKnows: JSX.EventHandler<HTMLInputElement, KeyboardEvent> = (
    e
  ) => {
    e.key === "Enter" && setQuery(e.currentTarget.value);
  };

  const openModal = () => {
    modalRef.style.display = "block";
  };

  const dismModal: JSX.EventHandlerUnion<
    HTMLDivElement | HTMLButtonElement,
    MouseEvent
  > = (e) => {
    if (e.target !== e.currentTarget) return;
    modalRef.style.display = "none";
  };

  createEffect(() => {
    data()?.propositions && setPropost(data()!.propositions);
  });

  return (
    <Show when={data()}>
      <div class="max-w-screen-xl mx-auto p-4 w-full text-lg prose dark:prose-ivnert max-w-prose">
        <h2>{data()?.know?.essence}</h2>
        <p>{data()?.know?.body}</p>
        <hr class="border-gray-700 my-4" />
      </div>
      <form
        action={toSubmit}
        method="post"
        class="max-w-screen-xl mx-auto p-4 w-full text-lg lg:max-w-prose flex flex-col"
      >
        <input name="id" value={data()!.id} hidden type="hidden" />

        <label class="my-8 inline-flex items-center cursor-pointer">
          <span class="me-4 text-lg font-medium text-gray-900 dark:text-gray-300">
            {t("disprove")}
          </span>
          <input
            type="checkbox"
            checked={data()!.negative ? true : false}
            name="negative"
            class="sr-only peer"
          />
          <div class="relative w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
        </label>

        <div class="my-2 flex items-center">
          <h1 class="grow font-semibold">{t("imply.desc_propositions")}</h1>
        </div>
        <button
          type="button"
          class="border border-2 border-dashed p-2 flex justify-center "
          onClick={openModal}
        >
          <PlusCircle size={6} class="text-gray-500" />
        </button>
        <ul class="list-disc">
          <For each={propos()}>
            {(propos) => (
              <li>
                <div class="my-2 flex flex-row items-center">
                  <div class=" grow text-left">
                    <input
                      type="hidden"
                      hidden
                      class="bg-white dark:bg-gray-800 mt-2 border border-gray-300 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white  dark:focus:ring-blue-500 mb-2 dark:focus:border-blue-500"
                      placeholder="condition"
                      value={propos.id}
                      name="propositionIds[]"
                    />
                    <strong>{propos.essence}</strong>
                    <br />

                    <span>{propos.body}</span>
                  </div>
                  <div class="flexs ">
                    <strong
                      class="rounded-full dark:text-white cursor-pointer"
                      onClick={[delPropsId, propos.id]}
                    >
                      <svg
                        class="w-4 h-4 text-gray-800 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 14 14"
                      >
                        <path
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                        />
                      </svg>
                    </strong>
                  </div>
                </div>
              </li>
            )}
          </For>
        </ul>
        <div
          id="defaultModal"
          class="fixed top-0 left-0 right-0 hidden z-10 w-full h-full bg-transparent lg:p-12"
          ref={modalRef}
          onClick={dismModal}
        >
          <div
            class="bg-gray-100 dark:bg-gray-800 max-w-screen-xl mx-auto h-full p-8"
            onClick={() => undefined}
          >
            <div class="flex flex-row items-stretch">
              <button
                class="border px-2 my-2"
                type="button"
                onClick={dismModal}
              >
                {t("close")}
              </button>
              <input
                class="bg-white dark:bg-gray-800 mt-2 border border-gray-300 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 mb-2 dark:focus:border-blue-500 w-full"
                onKeyDown={searchKnows}
              />
            </div>
            <Show when={searchData()}>
              <For each={searchData()?.hits}>
                {(item) => (
                  <div
                    class="border px-2 py-1 cursor-pointer mb-2"
                    onClick={() => addPropsId(item)}
                  >
                    <h4>{item.essence}</h4>
                    <h5>{item.body}</h5>
                  </div>
                )}
              </For>
            </Show>
          </div>
        </div>

        <label class="my-8 inline-flex items-center cursor-pointer">
          <span class="me-4 text-lg font-medium text-gray-900 dark:text-gray-300">
            {t("dash.publish")}
          </span>
          <input
            type="checkbox"
            checked={data()!.publishedAt ? true : false}
            name="published"
            class="sr-only peer"
          />
          <div class="relative w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
        </label>
        <button
          type="submit"
          disabled={state.pending}
          class="text-2xl border-2 rounded px-8 py-2"
        >
          {t("save")}
        </button>
      </form>
    </Show>
  );
}
