import {
  type RouteDefinition,
  type RouteSectionProps,
  createAsync,
  action,
  redirect,
} from "@solidjs/router";
import SuspenseWrap from "~/components/SuspenseWrap";
import { queryKnowImpliesCache } from "~/services";
import { update as updateKnowAct } from "~/services/knows";
import KnowForm from "~/components/KnowForm";

export const route = {
  load({ params }) {
    queryKnowImpliesCache(params.id);
  },
} satisfies RouteDefinition;

const toSubmit = action(async (formData: FormData) => {
  const essence = formData.get("essence")?.toString() || "";
  const body = formData.get("body")?.toString() || "";
  const id = formData.get("id")?.toString();
  if (!id) throw "id not found";
  await updateKnowAct(id, { essence, body });
  throw redirect(`/dash/knows`);
}, "n/:id/edit ");

export default function Edit(props: RouteSectionProps) {
  const id = () => props.params.id;
  const data = createAsync(() => queryKnowImpliesCache(id()), {
    deferStream: true,
  });

  return (
    <SuspenseWrap class="max-w-screen-xl mx-auto p-4 w-full">
      <KnowForm action={toSubmit} data={data} />
    </SuspenseWrap>
  );
}
