import { Show } from "solid-js";

import {
  A,
  cache,
  useSearchParams,
  type RouteDefinition,
  type RouteSectionProps,
  createAsync,
  redirect,
} from "@solidjs/router";
import { useAppState } from "~/AppContext";
import ImplyHead from "~/components/ImplyHead";
import { editReviewAct, queryReviewCache } from "~/services";

export const route = {
  load: ({ params }) => queryReviewCache(params.id),
} satisfies RouteDefinition;

export default function Submit(props: RouteSectionProps) {
  const { t } = useAppState();
  const id = () => props.params.id;
  const data = createAsync(() => queryReviewCache(id()));

  return (
    <Show when={data()}>
      <div class="p-4 max-w-prose">
        <Show when={data()!.imply}>
          <ImplyHead imply={data()!.imply!} />
        </Show>
        <form action={editReviewAct} method="post">
          <input hidden name="id" value={id()} />
          <h2 class="my-1">{t("dash.edit_review")}</h2>
          <div class="w-full mb-4 border border-gray-200 rounded-sm bg-gray-50 dark:bg-gray-700 dark:border-gray-600">
            <div class="px-2 py-2 bg-white rounded-b-lg dark:bg-gray-800">
              <label for="editor" class="sr-only">
                Publish post
              </label>
              <textarea
                id="editor"
                rows="8"
                class="block w-full px-0 text-sm text-gray-800 bg-white dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400 focus:border-0"
                placeholder={t("proposition_form.details_placeholder")}
                required
                name="content"
                value={data()?.content}
              />
            </div>
          </div>

          <button
            type="submit"
            class="items-center px-5 py-2.5 font-medium text-center rounded-sm outline outline-1 hover:dark:bg-gray-800 hover:bg-gray-100"
          >
            {t("save")}
          </button>
        </form>
      </div>
    </Show>
  );
}
