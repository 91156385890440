import { Show } from "solid-js";
import {
  type RouteDefinition,
  type RouteSectionProps,
  createAsync,
  useSubmission,
  useAction,
  redirect,
  useNavigate,
} from "@solidjs/router";
import { createTiptapEditor } from "solid-tiptap";
import StarterKit from "@tiptap/starter-kit";
import Image from "@tiptap/extension-image";
import Placeholder from "@tiptap/extension-placeholder";
import ToolbarContents from "~/components/Toolbar";
import ImplyHead from "~/components/ImplyHead";
import SuspenseWrap from "~/components/SuspenseWrap";
import { queryImplyFullCache, UpdateImplyAct } from "~/services";
import { useAppState } from "~/AppContext";
import { Spinner } from "~/components/icons";

export const route = {
  load({ params }) {
    queryImplyFullCache(params.id);
  },
} satisfies RouteDefinition;

export default function KnowFormula(props: RouteSectionProps) {
  const { t } = useAppState();
  const fromDash = () => !!props.location.query.fromDash;
  const nav = useNavigate();
  const id = () => props.params.id;
  const data = createAsync(() => queryImplyFullCache(id()), {
    deferStream: true,
  });

  const state = useSubmission(UpdateImplyAct);
  const updateAct = useAction(UpdateImplyAct);

  let ref!: HTMLDivElement;

  const editor = createTiptapEditor<HTMLDivElement>(() => ({
    element: ref!,
    extensions: [
      StarterKit,
      Image,
      Placeholder.configure({
        placeholder: t("content"),
        emptyNodeClass:
          "first:before:text-gray-400 first:before:content-[attr(data-placeholder)]",
      }),
    ],
    content: data()?.inference,
    editorProps: {
      attributes: {
        class: "relative py-2  focus: border-none outline-0 max-w-none",
      },
    },
  }));

  const prove = async () => {
    const _editor = editor();
    if (!_editor) throw "editor not ready";
    if (!id()) throw "know data not ready";
    const inference = editor()?.getHTML() || "";
    await updateAct(id(), { inference });
    if (fromDash()) return nav("/dash/implies");
    return nav(`/i/${id()}`);
  };

  return (
    <SuspenseWrap class="max-w-screen-lg mx-auto p-4 w-full dark:prose-invert prose max-w-none">
      <Show when={data()} fallback={<Spinner />}>
        {(imply) => (
          <>
            <ImplyHead imply={imply()} />

            <h1>{data()?.know?.body}</h1>
            <Show when={editor()} keyed>
              {(instance) => (
                <ToolbarContents
                  editor={instance}
                  class="text-xl w-full overflow-x-auto order-last bg-gray-100 md:bg-inherit md:static md:order-none relative "
                  iconClass="h-8 w-8"
                />
              )}
            </Show>
            <div class="overflow-y-auto">
              <div id="editor" ref={ref} class="absolute md:relative" />
            </div>
            <div class="flex flex-row mt-4 gap-10">
              <button
                type="submit"
                disabled={state.pending}
                class="grow border rounded px-2"
                onClick={prove}
              >
                {t("save")}
              </button>
            </div>
          </>
        )}
      </Show>
    </SuspenseWrap>
  );
}
