import {
  type RouteDefinition,
  type RouteSectionProps,
  createAsync,
} from "@solidjs/router";
import { Show } from "solid-js";
import { Spinner } from "~/components/icons";
import { useAppState } from "~/AppContext";
import { getArticleCache } from "~/services";

export const route = {
  load({ params }) {
    getArticleCache(params.id);
  },
} satisfies RouteDefinition;

export default function Know(props: RouteSectionProps) {
  const id = () => props.params.id;
  const data = createAsync(() => getArticleCache(id()), {
    deferStream: true,
  });
  const { t } = useAppState();

  return (
    <Show
      when={data()}
      fallback={
        <div>
          <Spinner />
        </div>
      }
    >
      <div class="px-2 mt-8">
        <article class="prose prose-xl dark:prose-invert px-2">
          <h1>{data()?.title}</h1>
          <div innerHTML={data()?.content} />
        </article>
      </div>
    </Show>
  );
}
