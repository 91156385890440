import { For, Show, JSX, Suspense, createSignal, onCleanup } from "solid-js";
import { isServer } from "solid-js/web";
import { A, cache, createAsync, type RouteDefinition } from "@solidjs/router";
import { tryOnCleanup } from "@solid-primitives/utils";
import { Title } from "@solidjs/meta";
import ImplyHead from "~/components/ImplyHead";
import { Spinner } from "~/components/icons";
import { useAppState } from "~/AppContext";
import { getRedis } from "~/lib/server";
import { DATA_STORE, HOME_LIST_ID } from "~/lib/const";

const size = 20;
const homeData = async (before: string) => {
  "use server";
  const rc = await getRedis();
  const list = await rc.zRangeByScore(HOME_LIST_ID, before, 0, {
    LIMIT: { offset: 0, count: size },
  });
  // await new Promise((res) => setTimeout(res, 500)); // test scrolling
  if (list.length == 0) return undefined;
  const bytesList = await rc.hmGet(DATA_STORE, list);
  const data = bytesList.map((s) => JSON.parse(s));
  return data;
};

const homeImpliesCache = cache(homeData, "home#Implies");

export const route = {
  load: () => {
    homeImpliesCache("-inf");
    // getUserCache();
  },
} satisfies RouteDefinition;

const showMore: JSX.EventHandler<HTMLDivElement, MouseEvent> = (e) => {
  e.currentTarget.classList.remove("max-h-80");
  e.currentTarget.classList.remove("overflow-hidden");
};

const tellMore: JSX.EventHandler<HTMLDivElement, MouseEvent> = (e) => {
  const prt = e.currentTarget.parentElement;
  if (prt) {
    prt.classList.remove("max-h-80", "overflow-hidden");
  }
  e.currentTarget.classList.remove(
    "hover:prose-headings:text-gray-400",
    "hover:text-gray-400",
    "cursor-pointer"
  );
};

export default function Home() {
  const { t } = useAppState();
  const [items, setItems] = createSignal<string[]>(["-inf"]);

  return (
    <main class="max-w-screen-lg mx-auto px-4 w-full mt-8 flex gap-2 items-start">
      <Title>{t("app.title")}</Title>

      <div class="text-lg flex flex-col divide-y max-w-full">
        <For each={items()}>
          {(v, idx) => (
            <ListGroup
              isLast={() => idx() === items().length - 1}
              before={v}
              setBefore={(v) => setItems((p) => [...p, v])}
            />
          )}
        </For>
      </div>
      {/* <Show when={current()}>
        <div class="grow border-2 border-gray-300 min-h-40 hidden lg:block p-4">
          <div class="flex gap-4">
            <div class="flex flex-col">
              <div class="text-center">{t("implies")}</div>
              <div class="w-20 h-20 p-1 flex justify-center items-center rounded-full border-2 border-gray-800">
                <span class="text-ellipsis overflow-hidden">
                  2329998839882{current()?.impliesCount}
                </span>
              </div>
            </div>
            <div class="flex flex-col">
              <div class="text-center">{t("knows")}</div>
              <div class="w-20 h-20 p-1 flex justify-center items-center rounded-full border-2 border-gray-800">
                <span class="text-ellipsis overflow-hidden">
                  2329998839882{current()?.knowsCount}
                </span>
              </div>
            </div>
          </div>
        </div>
      </Show> */}
    </main>
  );
}

function ListGroup({
  before,
  setBefore,
  isLast = () => false,
}: {
  isLast?: () => boolean;
  before: string;
  setBefore: (v: string) => void;
}) {
  const data = createAsync(() => homeImpliesCache(before), {
    deferStream: true,
  });
  const loadMore = () => {
    const _items = data();
    if (!_items) return;
    const item = _items[_items.length - 1];
    if (!item) return;
    setBefore(`(-${item.createdAt}`);
  };
  let infiniteScrollLoader: (el: HTMLHeadingElement) => void;
  if (!isServer) {
    const io = new IntersectionObserver((e) => {
      if (e.length > 0 && e[0]!.isIntersecting && isLast()) {
        loadMore();
      }
    });
    onCleanup(() => io.disconnect());
    infiniteScrollLoader = (el: HTMLHeadingElement) => {
      io.observe(el);
      tryOnCleanup(() => io.unobserve(el));
    };
  }

  return (
    <Suspense
      fallback={
        <div class="text-center my-16">
          <Spinner size={10} class="inline-block" />
        </div>
      }
    >
      <For each={data()}>
        {(imply, idx) => (
          <div
            class="flex flex-col max-h-80 overflow-hidden py-2"
            onClick={showMore}
          >
            <A
              href={`/i/${imply.id}`}
              class="ml-2 grow flex flex-col hover:text-blue-800"
            >
              <ImplyHead imply={imply} />
            </A>
            <div
              class="ml-2 prose dark:prose-invert cursor-pointer hover:prose-headings:text-gray-400 hover:text-gray-400"
              innerHTML={imply.inference}
              onClick={tellMore}
            />
          </div>
        )}
      </For>
      <Show when={isLast()}>
        <Show when={data()}>
          <h1 ref={infiniteScrollLoader!}></h1>
        </Show>
      </Show>
    </Suspense>
  );
}
