import { For, Show, Suspense } from "solid-js";
import {
  A,
  cache,
  createAsync,
  useSearchParams,
  type RouteDefinition,
  type RouteSectionProps,
} from "@solidjs/router";
import { Spinner, AdjustmentsHorizontal, Pen } from "~/components/icons";
import { Pagination } from "~/components/Pagination";
import ImplyHead from "~/components/ImplyHead";
import { currentUserIdCache } from "~/services";
import * as implySvc from "~/services/implies";
import { format } from "date-fns";

const limit = 10;
const getImplies = async (q: string, page: number) => {
  "use server";
  const userId = await currentUserIdCache();
  if (!userId) throw new Error("Not authed");
  // await new Promise((res) => setTimeout(res, 3000));
  return await implySvc.query(
    { authorId: userId },
    {
      size: limit,
      page,
      // withAuth: true,
      withKnow: true,
      withPropositions: true,
    }
  );
};

const getImpliesCache = cache(getImplies, "dash/implies");

export const route = {
  load({ location }) {
    void getImpliesCache(location.query.q || "", +location.query.page || 1);
  },
} satisfies RouteDefinition;

export default function DashImplies(props: RouteSectionProps) {
  const query = () => props.location.query.q || "";
  const page = () => +props.location.query.page || 1;
  const data = createAsync(() => getImpliesCache(query(), page()), {
    deferStream: true,
  });
  const [, setSearchParams] = useSearchParams();

  return (
    <Suspense
      fallback={
        <div class="text-center my-16">
          <Spinner size={10} class="inline-block" />
        </div>
      }
    >
      <Show when={data()}>
        <div class="divide-y divide-dotted divide-gray-800 flex flex-col text-lg mb-4 gap-4 p-4">
          <For each={data()?.hits || []}>
            {(imply, i) => (
              <div class="flex flex-row box-border pt-4">
                <A
                  href={`/i/${imply.id}`}
                  class="grow flex flex-col justify-between"
                >
                  <ImplyHead imply={imply} />
                  <span class="text-sm text-gray-600">
                    {format(imply.createdAt, "yy-MM-dd HH:mm")}
                  </span>
                </A>
                <div class="flex">
                  <A
                    href={`/i/${imply.id}/edit?fromDash=1`}
                    class="w-20 h-20 text-center flex justify-center items-center"
                  >
                    <Pen size={6} />
                  </A>
                  <A
                    href={`/i/${imply.id}/editPropos`}
                    class="pl-7 h-20 text-center flex justify-center items-center"
                  >
                    <AdjustmentsHorizontal size={6} />
                  </A>
                </div>
              </div>
            )}
          </For>
        </div>
        <Pagination
          class=" p-4"
          totalPage={() => data()!.totalPages}
          page={() => data()!.page}
          goPrev={() => {
            setSearchParams({ page: data()!.page - 1 });
          }}
          goNext={() => {
            setSearchParams({ page: data()!.page + 1 });
          }}
        />
      </Show>
    </Suspense>
  );
}
