import { For, Show, Suspense } from "solid-js";
import {
  A,
  cache,
  useSearchParams,
  type RouteDefinition,
  type RouteSectionProps,
  createAsync,
} from "@solidjs/router";
import { Spinner, Pen } from "~/components/icons";
import { Pagination } from "~/components/Pagination";
import { currentUserIdCache } from "~/services";
import * as repo from "~/repos/know";

const limit = 10;

const getKnows = cache(async (q: string, page: number) => {
  "use server";
  const userId = await currentUserIdCache();
  if (!userId) return undefined;
  return await repo.query({ authorId: userId }, { size: limit, page });
}, "dash/knows");

export const route = {
  load({ location }) {
    getKnows(location.query.q || "", +location.query.page || 1);
  },
} satisfies RouteDefinition;

export default function DashKnows(props: RouteSectionProps) {
  const query = () => props.location.query.q;
  const page = () => +props.location.query.page || 1;
  const data = createAsync(() => getKnows(query(), page()), {
    deferStream: true,
  });
  const [, setSearchParams] = useSearchParams();

  return (
    <Suspense
      fallback={
        <div class="text-center my-16">
          <Spinner size={10} class="inline-block" />
        </div>
      }
    >
      <Show when={data()}>
        <div class="divide-y divide-dotted divide-gray-800 dark:divide-gray-900 mb-4 p-4">
          <For each={data()?.hits}>
            {(know) => (
              <div class="flex flex-row">
                <A
                  class="grow py-1 block max-w-none prose dark:prose-invert"
                  href={`/n/${know.id}`}
                >
                  <h3>{know.essence}</h3>
                  <h5>{know.body}</h5>
                </A>
                <div class="flex flex-col">
                  <A
                    href={`/n/${know.id}/edit`}
                    class="pl-7 h-20 text-center flex justify-center items-center"
                  >
                    <Pen size={6} />
                  </A>
                </div>
              </div>
            )}
          </For>
        </div>
        <Pagination
          class=" p-4"
          totalPage={() => data()!.totalPages}
          page={() => data()!.page}
          goPrev={() => {
            setSearchParams({ page: data()!.page - 1 });
          }}
          goNext={() => {
            setSearchParams({ page: data()!.page + 1 });
          }}
        />
      </Show>
    </Suspense>
  );
}
